body{
    --captain-color: rgba(255,141,0,1);
    --captain-color-hover: rgba(255,141,0,0.2);
    --captain-background: rgba(15,23,42,1);
    --captain-background-slate: rgba(30,41,59,1);
    --captain-line: rgba(255, 255, 255, 0.200);
    background-color: var(--captain-background);
}
/*Diary App Layout*/
.diary_layout{
    height: 100vh;
    display: flex;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 10px;
    color: white;
    /* position: relative; */
}
/*Sidebar*/
.sidebar{
    border-right: 1px solid var(--captain-line);
    flex: 0.3;
    min-width: 250px;
    margin-left: 20px;
    margin-right: 0px;
}
.sidebar_r{
    border-right: 1px solid var(--captain-line);
    flex: 0.3;
    margin-right: 0px;
}

.sidebar_captainicon {
    margin: 10px 15px;
    margin-bottom: 20px;
}

.sidebarOptions{
    display: flex;
    align-items: center;
    cursor: pointer;
    /* color: white; */
}

.sidebarOptions:hover{
    background-color: var(--captain-color-hover);
    border-radius: 30px;
    color: var(--captain-color);
    transition: color 100ms ease-in;
}

.sidebarOptions > .MuiSvgIcon-root{
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 30px !important;
}

.sidebarOptions > h2{
    font-weight: 800;
    font-size: 20px;
    margin-right: 20px;
    
}

.sidebar_button{
    background-color: var(--captain-color) !important;
    color: white !important;
    border: none !important;
    font-weight: 900 !important;
    border-radius: 30px !important;
    text-transform: inherit !important;
    height: 50px !important;
    margin-top: 20px !important;
    width: 90% !important;
}

.sidebar_button_md{
    background-color: var(--captain-color) !important;
    color: white !important;
    border: none !important;
    font-weight: 400 !important;
    border-radius: 60px !important;
    text-transform: inherit !important;
    height: 50px !important;
    margin-top: 20px !important;
    width: 10% !important;
}

.sidebarOptions--active{
    color: var(--captain-color);

}
/*Feed*/
.news, .escape, .nightmare, .spy, .profile{
    flex: 0.4;
    border-right: 1px solid var(--captain-line);
    min-width: fit-content;
    overflow-y: scroll;
}
.feed::-webkit-scrollbar, .news::-webkit-scrollbar, .escape::-webkit-scrollbar, .nightmare::-webkit-scrollbar,
.spy::-webkit-scrollbar, .profile::-webkit-scrollbar{
    display: none;
}
.feed, .news, .escape, .nightmare, .spy, .profile{
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.feed_header, .news_header, .escape_header, .nightmare_header, .spy_header, .profile_header{
    position: sticky;
    top: 0;
    background-color: var(--captain-background);
    z-index: 100;
    border-bottom: 1px solid var(--captain-line);
    padding: 15px 20px;
    font-weight: 800;
    font-size: 20px;
}

/*ToWrite Box*/
.towriteBox{
    padding-bottom: 10px;
    border-bottom: 1px solid var(--captain-line);
    padding-right: 10px;
}

.towriteBox > form{
    display: flex;
    flex-direction: column;
}

.towriteBox_input{
    padding: 20px;
    display: flex;
}

.towriteBox_input > input{
    background-color: transparent;
    flex: 1;
    margin-left: 20px;
    font-size: 20px;
    border: none;
    outline: none;
}

.towriteBox_image{
    background-color: transparent;
    border: none;
    padding: 10px;
    outline: none;
}

.towrite_button{
    background-color: var(--captain-color) !important;
    color: white !important;
    border: none !important;
    font-weight: 900 !important;
    border-radius: 30px !important;
    text-transform: inherit !important;
    height: 45px !important;
    margin-top: 20px !important;
    width: 120px !important;
    margin-left: auto !important;
}

/*Post*/
.post{
    display: flex;
    align-items: stretch;
    border-bottom: 1px solid var(--captain-line);
    padding-bottom: 10px;
}
.post_body{
    flex: 1;
    padding: 10px;
}
.post_body > video{
    width: 600;
    height: 400;
}
.post_avatar{
    padding: 20px;
}
.post_headerText > h3{
    font-weight: 800;
    font-size: 15px;
    margin-bottom: 5px;
}
.post_headerSpecial{
    font-weight: 600;
    font-size: 12px;
    color: gray;
}
.post_headerDescription{
    font-size: 15px;
    margin-bottom: 10px;
    max-width: 450px;
}
.post_badge{
    font-size: 14px !important;
    color: var(--captain-color);
}
.post_image{
    width: 100%;
    max-width: 450px;
    max-height: 400px;
    border-radius: 20px;
}
.post_footer{
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
}

/*Widgets*/
.widgets{
    flex: 0.3;
}
.widgets_input{
    display: flex;
    align-items: center;
    background-color: var(--captain-background-slate);
    padding: 10px;
    border-radius: 20px;
    margin-top: 10px;
    margin-left: 20px;
}
.widgets_input > input{
    background-color: transparent;
    border: none;
    outline: none;
}
.widgets_searchIcon{
    color: gray;
}
.widgets_widgetsContainer{
    margin-top: 15px;
    margin-left: 20px;
    padding: 20px;
    background-color: var(--captain-background-slate);
    border-radius: 20px;
}
.widgets_widgetsContainer > h2{
    font-weight: 800;
    font-size: 18px;
}
.widgets_widgetsContainer > article{
    background-color: transparent;
}

